<template>
    <v-container>
        <div class="search-header">
            <h1 class="text-h1 mt-3 mb-7">Search results</h1>
            <v-text-field
                v-model="query"
                :append-icon="query ? 'fal fa-search' : ''"
                placeholder="Search..."
                @input="search"
                :loading="isSearching"
                class="ml-auto"
            ></v-text-field>
        </div>

        <v-divider></v-divider>

        <div class="text-center mt-5" v-if="isSearching">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
        <v-alert v-if="!isSearching && query && searchResults.length === 0" color="warning" class="my-5">
            No results found for "<em>{{ query }}</em>". Please try again with another search query.
        </v-alert>
        <p v-if="!isSearching && searchResults.length > 0" class="my-5">
            <span v-if="searchResults.length > 1"><strong>{{ searchResults.length }} results</strong> found for "<em>{{ query }}</em>".</span>
            <span v-else><strong>{{ searchResults.length }} result</strong> found for "<em>{{ query }}</em>".</span>
        </p>
        <v-row class="mb-3">
            <v-col md="7" lg="5">
                <v-list two-line v-if="!isSearching && searchResults.length > 0" elevation="1" nav class="pa-0">
                    <v-list-item v-for="(result, index) in searchResults" :key="index" @click="goToSearchResult(result)" class="mb-n1">
                        <v-list-item-content class="px-2">
                            <v-list-item-title>
                                {{ result.label }}
                                <v-chip
                                    v-if="result.archived"
                                    class="ml-1"
                                    color="red lighten-2"
                                    x-small
                                >Discontinued</v-chip>
                                <v-chip
                                    v-else-if="result.expiring"
                                    class="ml-1"
                                    color="warning"
                                    x-small
                                >To be expired</v-chip>
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="result.group">{{ result.group }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon>
                            <v-icon color="grey lighten-1">fal fa-chevron-right</v-icon>
                          </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import apiClient from "@/api/apiClient";
import routeSearcher from "../router/routeSearcher";

export default {
    name: "Search",
    data: () => ({
        isSearching: false,
        isSearchQueued: false,
        query: '',
        searchResults: [],
    }),
    mounted() {
        this.query = this.$route.params.query;
        if (this.query) {
            this.search();
        }
        this.$store.dispatch('setPageTitle', 'Search')
    },
    watch: {
        '$route.params' () {
            this.query = this.$route.params.query;
            if (this.query) {
                this.search();
            }
        },
    },
    methods: {
        search() {
            if (this.isSearching) {
                this.isSearchQueued = true;
                return;
            }

            this.isSearchQueued = false;

            // Ignore if empty string
            if (this.query === null || this.query.trim() === '') {
                this.searchResults = [];
                return;
            }

            this.searchResults = [];
            this.isSearching = true;

            apiClient.get('/search', { params: {
                    query: this.query,
                }}).then(response => {
                if (response.status === 200) {
                    this.searchResults = response.data;
                } else {
                    console.error(response.data.error);
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige zoekopdracht'});
                }
                if (this.isSearchQueued) {
                    this.search();
                }
            }).catch(() => {
                return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige zoekopdracht'});
            }).finally(() => {
                this.isSearching = false;
            });
        },
        goToSearchResult(result) {
            routeSearcher.goToSearchResult(this.$router, result);
        },
    },
}
</script>

<style scoped>

</style>
